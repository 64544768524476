import * as React from 'react';
import { ReactNode } from 'react';
import { ColorType } from 'grommet/utils';
import { Box, Heading, Text } from 'grommet';
import { useTranslation } from 'react-i18next';

export interface SectionProps {
  id: string;
  color: ColorType;
  background: ColorType;
  title: string;
  children: ReactNode;
}

export function Section(props: SectionProps) {
  const { color, background, title, children, id } = props;

  const { t } = useTranslation();

  return (
    <Box
      pad="large"
      background={{
        color: background,
      }}
      gap="medium"
      id={id}
    >
      <Heading level="1" color={color}>
        {t(title)}
      </Heading>
      <Text color={color}>{children}</Text>
    </Box>
  );
}
