import * as React from 'react';
import { Box, Heading, Text } from 'grommet';
import { useTranslation } from 'react-i18next';
import { CustomTheme } from '../theme/customTheme';
import { useResponsiveContext } from '../components/useResponsiveContext';

export function Kopfteil() {
  const { t } = useTranslation();
  const { isSmall } = useResponsiveContext();

  return (
    <Box
      height={{ min: '130px', height: isSmall ? '150px' : '300px' }}
      background={{
        color: CustomTheme.kopfteil.background,
        image: `url(${process.env.PUBLIC_URL}/static/bg3.jpg)`,
      }}
      pad="large"
      align={isSmall ? 'center' : 'end'}
    >
      <Heading level="1" style={{ fontSize: '50px' }}>
        {t('Lioudmila Harrer')}
      </Heading>
      <Text style={{ fontSize: '24px' }}>{t('München')}</Text>
    </Box>
  );
}
