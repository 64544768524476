import * as React from 'react';
import { Lightbox } from 'yet-another-react-lightbox';
import { Slide } from 'yet-another-react-lightbox/dist/types';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';

import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import { IGalerieItem } from './Galerie';

function convertToSlides(items: IGalerieItem[]): Slide[] {
  return items.map((item) => ({
    src: `${process.env.PUBLIC_URL}/${item.image}`,
    //title: item.title,
    description: `${item.title} \n ${item.size}, ${item.year}`,
    //'Blumengarten in Frühling \n 30x40, 2021',
  }));
}

export interface LightboxInstanceProps {
  items: IGalerieItem[];
  slideIndex: number;
  open: boolean;
  onClose: () => void;
}

export function LightboxInstance(props: LightboxInstanceProps) {
  const { items, slideIndex, open, onClose } = props;
  const slides = convertToSlides(items);
  return (
    <Lightbox
      open={open}
      index={slideIndex}
      close={onClose}
      slides={slides}
      /*styles={{
        root: { '--yarl__slide_title_color': 'red' },
      }}*/
      captions={{ descriptionTextAlign: 'center' }}
      styles={{
        container: { fontFamily: 'Exo2' },
        root: { '--yarl__slide_description_text_align': 'center' },
      }}
      plugins={[Captions, Slideshow, Thumbnails]}
    />
  );
}
