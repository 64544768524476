import * as React from 'react';
import { CustomTheme } from '../theme/customTheme';
import { Section } from '../components/Section';
import { Box, Text } from 'grommet';
import { useTranslation } from 'react-i18next';
import { emailRender } from '../helpers';
import { useResponsiveContext } from '../components/useResponsiveContext';

export function Kontakt() {
  const { i18n } = useTranslation();
  const { isSmall } = useResponsiveContext();

  return (
    <Section
      id="Kontakt"
      background={CustomTheme.kontakt.background}
      color={CustomTheme.kontakt.color}
      title="Kontakt"
    >
      <Box direction={isSmall ? 'column' : 'row'} gap="large">
        {i18n.language === 'be' ? (
          <Text>
            Людміла Харрэр
            <br />
            <br />
            www.harrer-malerei.de
            <br />
            Электронная пошта: {emailRender('lh@harrer-malerei.de')}
            <br />
            Tэлефон: +49 176 555 93 592
            <br />
            <br />
            Выдавец і адказны рэдактар: Людміла Харрэр
            <br />
            <br />
            Макет гэтага сайта, графіка і іншыя файлы абаронены аўтарскім
            правам.
            <br />
            <br />
            &copy; Людміла Харрэр, Мюнхен
          </Text>
        ) : (
          <Text>
            Lioudmila Harrer
            <br />
            <br />
            www.harrer-malerei.de
            <br />
            E-Mail: {emailRender('lh@harrer-malerei.de')}
            <br />
            Telefon: +49 176 555 93 592
            <br />
            <br />
            Herausgeber und Verantwortlicher: Lioudmila Harrer
            <br />
            <br />
            Das Layout dieses Internetauftrittes, die verwendeten Grafiken sowie
            die sonstigen Inhalte sind urheberrechtlich geschützt.
            <br />
            <br />
            &copy; Lioudmila Harrer, München
          </Text>
        )}
      </Box>
    </Section>
  );
}
