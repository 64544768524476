export const CustomColors = {
  color1: '#bfbfbf',
  color2: '#e5e5e5',
  color3: '#4b4c60',
  color5: '#184764',
  color6: '#7f81a0',
  color7: '#acaed7',
  color9: '#3d3d3d',
  color10: '#727fce',
  color11: '#818fe7',
};
