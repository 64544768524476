import { useEffect, useState } from 'react';

export function useSticky(stickyAfter: number) {
  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isSticky = () => {
    setSticky(window.scrollY >= stickyAfter ? true : false);
  };

  return sticky;
}
