import * as React from 'react';
import { Box } from 'grommet';
import { Kopfteil } from './Kopfteil';
import { UeberMich } from './UeberMich';
import { Galerie } from './galerie/Galerie';
import { Ausstellungen } from './ausstellungen/Ausstellungen';
import { Presse } from './pesse/Presse';
import { Kontakt } from './Kontakt';
import { Menu } from '../components/menu/Menu';

export function MainPage() {
  return (
    <Box>
      <Kopfteil />
      <Menu />
      <UeberMich />
      <Galerie />
      <Ausstellungen />
      <Presse />
      <Kontakt />
    </Box>
  );
}
