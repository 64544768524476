import { IPresseItem } from './Presse';

export const presseListe_BE: IPresseItem[] = [
  {
    document: 'presse/katalog.pdf',
    preview: 'presse/katalog-preview.jpg',
    title: 'Каталог, 2016 (PDF 4,8 MB)',
  },
  {
    document: 'presse/mart.jpg',
    preview: 'presse/mart-logo.jpg',
    title: 'M-Art Magazin 2020',
  },
  {
    document: 'presse/ingolstadt-2.jpg',
    preview: 'presse/ingolstadt-2-logo.jpg',
    title: 'Сертыфікат. 1 Мiжнароднае Арт экспа. Iнгольштадт 2017',
  },
  {
    document: 'presse/sos-kinderdorf.jpg',
    preview: 'presse/sos-kinderdorf-preview.jpg',
    title: 'Сертыфікат. SOS Kinderdorf',
  },
  {
    document: 'presse/bm.pdf',
    preview: 'presse/bm-logo.png',
    title: 'Buongiorno Monaco / Dezember 2016',
  },
  {
    document: 'presse/ingolstadt-1.pdf',
    preview: 'presse/logo-citicon.jpg',
    title: 'Citicon Mai / Juni 2017',
  },
  {
    document: 'presse/ingolstadt-3.jpg',
    preview: 'presse/logo-citicon.jpg',
    title: 'Citicon Juni / Juli 2017',
  },
  {
    document: 'presse/doanukurier.png',
    preview: 'presse/logo-donaukurier.jpg',
    title: 'Donaukurier 22.05.2017',
  },
  {
    document: 'presse/schwabing.pdf',
    preview: 'presse/logo-wochenanzeiger.png',
    title: 'Wochenanzeiger München, 08.11.2016',
  },
  {
    document: 'presse/ukraine.png',
    preview: 'presse/ukraine_preview.png',
    title: 'Münchener Wochen Anzeiger, 02.02.2015',
  },
  {
    document: 'presse/4022012.pdf',
    preview: 'presse/4022012_preview.jpg',
    title: 'Hallo München 04.02.2012',
  },
  {
    document: 'presse/1122012.pdf',
    preview: 'presse/1122012_preview.jpg',
    title: 'Werbe-Spiegel 01.02.2012',
  },
];

export const presseListe_DE: IPresseItem[] = [
  {
    document: 'presse/katalog.pdf',
    preview: 'presse/katalog-preview.jpg',
    title: 'Katalog, 2016 (PDF 4,8 MB)',
  },
  {
    document: 'presse/mart.jpg',
    preview: 'presse/mart-logo.jpg',
    title: 'M-Art Magazin 2020',
  },
  {
    document: 'presse/ingolstadt-2.jpg',
    preview: 'presse/ingolstadt-2-logo.jpg',
    title: '1. Ingolstädter ART EXPO INTERNATIONAL 2017',
  },
  {
    document: 'presse/sos-kinderdorf.jpg',
    preview: 'presse/sos-kinderdorf-preview.jpg',
    title: 'SOS Kinderdorf Urkunde',
  },
  {
    document: 'presse/bm.pdf',
    preview: 'presse/bm-logo.png',
    title: 'Buongiorno Monaco 2016',
  },
  {
    document: 'presse/ingolstadt-1.pdf',
    preview: 'presse/logo-citicon.jpg',
    title: 'Citicon Mai / Juni 2017',
  },
  {
    document: 'presse/ingolstadt-3.jpg',
    preview: 'presse/logo-citicon.jpg',
    title: 'Citicon Juni / Juli 2017',
  },
  {
    document: 'presse/doanukurier.png',
    preview: 'presse/logo-donaukurier.jpg',
    title: 'Donaukurier 22.05.2017',
  },
  {
    document: 'presse/schwabing.pdf',
    preview: 'presse/logo-wochenanzeiger.png',
    title: 'Wochenanzeiger München, 08.11.2016',
  },
  {
    document: 'presse/ukraine.png',
    preview: 'presse/ukraine_preview.png',
    title: 'Münchener Wochen Anzeiger, 02.02.2015',
  },
  {
    document: 'presse/4022012.pdf',
    preview: 'presse/4022012_preview.jpg',
    title: 'Hallo München 04.02.2012',
  },
  {
    document: 'presse/1122012.pdf',
    preview: 'presse/1122012_preview.jpg',
    title: 'Werbe-Spiegel 01.02.2012',
  },
];
