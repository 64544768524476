import * as React from 'react';
import { Box } from 'grommet';
import { IAustellungItem } from './Ausstellungen';

export interface AusstellungItemProps {
  data: IAustellungItem;
}

export function AusstellungItem(props: AusstellungItemProps) {
  const { data } = props;

  return (
    <>
      <Box gridArea="date">{data.datum}</Box>
      <Box gridArea="place">{data.ort}</Box>
      <Box gridArea="title">{data.beschreibung}</Box>
    </>
  );
}
