import '../fonts/font.css';
import { CustomColors } from './customColors';
import { CustomizedThemeType } from '@mhb-ux/mhb-components';

export const GrommetTheme: CustomizedThemeType = {
  global: {
    colors: {
      background: CustomColors.color1,
      text: 'white',
    },
    font: {
      family: 'OpenSans, Helvetica, Arial, Verdana, sans-serif',
      size: '14px',
    },
    focus: { outline: undefined },
  },
  text: {
    xsmall: {
      size: '10px',
    },
    small: {
      size: '12px',
    },
    medium: {
      size: '14px',
    },
    large: {
      size: '20px',
    },
    xlarge: {
      size: '24px',
    },
    xxlarge: {
      size: '34px',
    },
  },
  heading: {
    extend: {
      // @ts-ignore
      letterSpacing: '-1px',
      lineHeight: '100%',
      fontWeight: '300',
      margin: 0,
      fontFamily: 'Exo2, Helvetica, Arial, Verdana, sans-serif',
    },
    level: {
      1: {
        font: 'Exo2, Helvetica, Arial, Verdana, sans-serif',
        medium: { size: '40px' },
      },
    },
  },
};
