import * as React from 'react';
import { Box, Grid } from 'grommet';
import Routing from './routing/Routing';
import { useResponsiveContext } from './components/useResponsiveContext';

export function IndexPage() {
  const { isSmall } = useResponsiveContext();
  return (
    <>
      {isSmall ? (
        <Routing />
      ) : (
        <Grid columns={['flex', 'auto', 'flex']}>
          <Box />
          <Box background="white" width="960px">
            <Routing />
          </Box>
          <Box />
        </Grid>
      )}
    </>
  );
}
