import * as React from 'react';
import { useRef, useState } from 'react';
import { Anchor, Box, Text } from 'grommet';
import { IPresseItem } from './Presse';

export interface PresseItemProps {
  data: IPresseItem;
}

export function PresseItem(props: PresseItemProps) {
  const { data } = props;
  const imgRef = useRef<HTMLImageElement>(null);
  const [justify, setJustify] = useState<'center' | 'start'>('center');

  return (
    <Anchor
      href={`${process.env.PUBLIC_URL}/static/${data.document}`}
      target="_blank"
    >
      <Box gap="small" align="center">
        <Box
          width="150px"
          height="150px"
          background="white"
          overflow="hidden"
          justify={justify}
        >
          <img
            ref={imgRef}
            src={`${process.env.PUBLIC_URL}/static/${data.preview}`}
            width="150px"
            alt={data.title}
            onLoad={() =>
              setJustify(
                imgRef.current?.height && imgRef.current.height > 150
                  ? 'start'
                  : 'center'
              )
            }
          />
        </Box>
        <Box width="150px">
          <Text textAlign="center">{data.title}</Text>
        </Box>
      </Box>
    </Anchor>
  );
}
