import * as React from 'react';
import { CustomTheme } from '../theme/customTheme';
import { Section } from '../components/Section';
import { Box, Text } from 'grommet';
import { useTranslation } from 'react-i18next';
import { useResponsiveContext } from '../components/useResponsiveContext';

export function UeberMich() {
  const { i18n } = useTranslation();
  const { isSmall } = useResponsiveContext();

  return (
    <Section
      id="UeberMich"
      background={CustomTheme.uebermich.background}
      color="white"
      title="Über mich"
    >
      <Box direction={isSmall ? 'column' : 'row'} gap="large">
        {isSmall && (
          <Box align="center">
            <span
              style={{
                position: 'relative',
                height: '200px',
                width: '200px',
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/static/uebermich2.jpg`}
                width="200px"
                style={{
                  position: 'absolute',
                  clip: 'rect(0px,200px,200px,0px)',
                }}
                alt=""
              />
            </span>
          </Box>
        )}

        {!isSmall && (
          <span>
            <img
              src={`${process.env.PUBLIC_URL}/static/uebermich2.jpg`}
              width="300px"
              alt=""
            />
          </span>
        )}

        <Box>
          {i18n.language === 'be' ? (
            <Box gap="large">
              <Box gap="small">
                <Text textAlign="end" style={{ fontStyle: 'italic' }}>
                  Мэта мастака – паказаць не сам прадмет, а тое, што знаходзіцца
                  паміж прадметам і вачыма мастака – атмасферу, немагчымае.
                </Text>
                <Text textAlign="end" style={{ fontStyle: 'italic' }}>
                  - Клод Монэ -
                </Text>
              </Box>

              <Text>
                Людміла Харрэр (Кулінкіна) нарадзілася 16 сакавіка 1958 года ў
                Беларусі, на Віцебшчыне, ў сям’і настаўнікаў. У 1975 годзе
                скончыла сярэднюю школу ў вёсцы Заспа Рэчыцкага раёну Гомельскай
                вобласці і працягнула сваё навучанне ў Гомельскім дзяржаўным
                універсітэце на гісторыка-філалагічным факультэце. З 1997 года
                Людміла Харэр жыве ў Мюнхене.
                <br />
                <br />
                Маляваць пачала ў 48 год. Выяўленчаму мастацтву яна вучылася ў
                такіх майстроў як Мixaл Mapтэрсан (Оттэрфiнг, 2009), беларускi
                мастак Mixaл Чарняускi (Мюнхен, 2010), ў школе мастацтва
                ўкраінскай мастачкі Алёны Калеснічэнка ў Мюнхене (2011), у школе
                мастацтва "Diemalschule" нямецкай мастачкi Мiрыям Пашке (2017).
                <br />
                <br />
                “Маляванне для мяне – гэта праяўленне гармоніі, спакою і волі, –
                гаворыць Людміла. – Яно дае мне магчымасць забыцца пра
                штодзённыя клопаты і акунуцца ў мае мары. Разнастайнасць фарбаў
                і таноў бударажыць маё ўяўленне, я атрымліваю асалоду ад кожнай
                хвіліны напісання новай карціны. Мая душа выяўляе сябе праз
                мастацтва”.
              </Text>
            </Box>
          ) : (
            <Box gap="large">
              <Box gap="small">
                <Text textAlign="end" style={{ fontStyle: 'italic' }}>
                  Die Aufgabe des Künstlers besteht darin, das darzustellen, was
                  zwischen dem Objekt und dem Künstler steht, nämlich die
                  Schönheit der Atmosphäre, das Unmögliche!
                </Text>
                <Text textAlign="end" style={{ fontStyle: 'italic' }}>
                  - Claude Monet -
                </Text>
              </Box>
              <Text>
                Lioudmila Harrer, geborene Kulinkina, wurde am 16. März 1958 in
                der Nähe von Vitebsk in Belarus in einer Lehrerfamilie geboren.
                Bis 1975 besuchte sie eine Mittelschule in Dorf Saspa, Bezirk
                Rechitza, Gebiet Gomel, und absolvierte anschließend ihr Studium
                an der historisch-philologischen Fakultät der staatlichen
                Universität Gomel (Lehrgang „Weißrussische und russische Sprache
                und Literatur“). Seit 1997 lebt sie in München.
                <br />
                <br />
                Zu malen begann Lioudmila Harrer mit 48 Jahren. Ihr
                Kunsthandwerk lernt sie in der Malschule von Michael Martersen
                in Otterfing (2009), in der Kunstschule des weißrussischen
                Künstlers Michail Tschernjavski in München (2010), in der Schule
                der ukrainischen Künstlerin Jelena Kolesnichenko in München
                (2011) und in der "Diemalschule" der deutschen Künstlerin Miriam
                Paschke in München (2017).
                <br />
                <br />
                Ihr Verhältnis zur Malerei beschreibt Lioudmila Harrer mit den
                Worten: „Die Malerei gibt mir Harmonie, Gelassenheit und
                Freiheit. Sie lässt mich den Alltag vergessen und bringt mich
                zum träumen. Die Farben faszinieren mich. Ich genieße die Zeit,
                die ich beim Schaffen eines Bildes verbringe. Durch die Malerei
                spricht meine Seele“.
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    </Section>
  );
}
