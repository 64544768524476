import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

export function HelmetConfiguration() {
  const { t } = useTranslation();
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{t('page.title')}</title>
      <link rel="canonical" href="http://mysite.com/example" />
      <meta name="keywords" content={t('page.keywords')} />
      <meta name="description" content={t('page.description')} />
      <meta name="author" content="Lioudmila  Harrer" />
    </Helmet>
  );
}
