export interface IMenuitem {
  title: string;
  sectionId: string;
}

export const menuItems: IMenuitem[] = [
  {
    sectionId: 'UeberMich',
    title: 'Über mich',
  },
  {
    sectionId: 'Galerie',
    title: 'Galerie',
  },
  {
    sectionId: 'Ausstellungen',
    title: 'Ausstellungen',
  },
  {
    sectionId: 'Presse',
    title: 'Presse',
  },
  {
    sectionId: 'Kontakt',
    title: 'Kontakt',
  },
];
