import * as React from 'react';
import PhotoAlbum, { Photo, PhotoProps } from 'react-photo-album';
import { ResponsiveTooltip } from '../../components/ResponsiveTooltip';
import { IGalerieItem } from './Galerie';
import { useResponsiveContext } from '../../components/useResponsiveContext';

function convertToPhotos(items: IGalerieItem[]): Photo[] {
  return items.map((item) => {
    const title = `${item.title}, ${item.size}, ${item.year}`;
    return {
      alt: title,
      height: item.height,
      src: `${process.env.PUBLIC_URL}/${item.preview}`,
      title: title,
      width: item.width,
    };
  });
}

export interface PhotoAlbumInstanceProps {
  items: IGalerieItem[];
  onItemClick: (index: number) => void;
}

export function PhotoAlbumInstance(props: PhotoAlbumInstanceProps) {
  const { items, onItemClick } = props;
  const { isSmall } = useResponsiveContext();

  const photos: Photo[] = convertToPhotos(items);

  const renderPhoto = ({
    imageProps: { style, ...rest },
    photo,
  }: PhotoProps) => (
    <ResponsiveTooltip content={photo.title}>
      <img
        style={{
          ...style,
          boxShadow:
            '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
          //backgroundColor: '#bfbfbf',
          transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        }}
        {...rest}
        alt=""
      />
    </ResponsiveTooltip>
  );

  return (
    <PhotoAlbum
      layout="rows"
      photos={photos}
      spacing={30}
      padding={20}
      columns={isSmall ? 2 : 4}
      renderPhoto={renderPhoto}
      onClick={(event, photo, index) => onItemClick(index)}
    />
  );
}
