/**
 * This file is intended to be a central place to import two types of configuration
 * into our app - one from the `config.json` file and the other from the runtime
 * environment variables that are on the window object. Rather than spread logic
 * across the app of how to retrieve this, we use this central place to handle
 * access to it.
 */

export interface IEnvConfig {
  BACKEND_MOCK: string;
  BACKEND_URL: string;
  ENVIRONMENT: string;
  FRONTEND_PORT: string;
  APP_NAME: string;
  PROJECT_VERSION?: string;
  BUILD_TIME?: string;
}

export const envConfig: IEnvConfig =
  process.env.NODE_ENV === 'production'
    ? {
        BACKEND_MOCK: 'false',
        BACKEND_URL: 'https://extract.mhb-dev.com',
        ENVIRONMENT: 'local',
        APP_NAME: 'harrer-malerei-web',
        FRONTEND_PORT: '3000',
        PROJECT_VERSION: '',
        BUILD_TIME: '',
      }
    : {
        BACKEND_MOCK: 'true', // Mock Service Worker enabled/disabled
        BACKEND_URL: 'http://localhost:10710',
        //BACKEND_MOCK: 'false',
        //BACKEND_URL: 'https://extract.mhb-dev.com',
        ENVIRONMENT: 'local',
        APP_NAME: 'harrer-malerei-web',
        FRONTEND_PORT: '3000',
        PROJECT_VERSION: '',
        BUILD_TIME: '',
      };
//TODO: pep2
/*
export const envConfig: IEnvConfig =
  process.env.NODE_ENV === 'production'
    ? (window as any).env
    : {
        BACKEND_MOCK: 'true', // Mock Service Worker enabled/disabled
        BACKEND_URL: 'http://localhost:10710',
        //BACKEND_MOCK: 'false',
        //BACKEND_URL: 'https://extract.mhb-dev.com',
        ENVIRONMENT: 'local',
        APP_NAME: 'mhb-posteingangsprozess-web',
        FRONTEND_PORT: '3000',
        PROJECT_VERSION: '',
        BUILD_TIME: '',
      };
  */
