import { CustomColors } from './customColors';

export const CustomTheme = {
  kopfteil: {
    background: CustomColors.color3,
  },
  uebermich: {
    background: CustomColors.color3,
    color: 'white',
  },
  galerie: {
    background: 'white',
    color: CustomColors.color5,
  },
  ausstellungen: {
    background: CustomColors.color6,
    color: 'white',
  },
  presse: {
    background: CustomColors.color7,
    color: CustomColors.color9,
  },
  kontakt: {
    background: CustomColors.color3,
    color: 'white',
  },
  menu: {
    background: CustomColors.color10,
    color: 'white',
    hover: CustomColors.color11,
  },
};
