import * as React from 'react';
import { useState } from 'react';
import { CustomTheme } from '../../theme/customTheme';
import { Section } from '../../components/Section';
import { useTranslation } from 'react-i18next';
import { LightboxInstance } from './LightboxInstance';
import { PhotoAlbumInstance } from './PhotoAlbumInstance';
import { galerieListe_BE } from './daten_BE';
import { galerieListe_DE } from './daten_DE';

export interface IGalerieItem {
  image: string;
  preview: string;
  title: string;
  year?: number;
  size?: string;
  width: number;
  height: number;
}

export function Galerie() {
  const { i18n } = useTranslation();
  const liste = (
    i18n.language === 'be' ? galerieListe_BE : galerieListe_DE
  ).sort((item1, item2) => {
    if (item1.year && item2.year) return item1.year > item2.year ? -1 : 1;
    return 0;
  });
  const [slideIndex, setSlideIndex] = useState(-1);

  return (
    <Section
      id="Galerie"
      background={CustomTheme.galerie.background}
      color={CustomTheme.galerie.color}
      title="Galerie"
    >
      <PhotoAlbumInstance
        items={liste}
        onItemClick={(index) => setSlideIndex(index)}
      />
      <LightboxInstance
        items={liste}
        slideIndex={slideIndex}
        open={slideIndex >= 0}
        onClose={() => setSlideIndex(-1)}
      />
    </Section>
  );
}
