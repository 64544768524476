import * as React from 'react';
import { Box, ResponsiveContext, Text } from 'grommet';
import { Button } from '@mhb-ux/mhb-components';
import { useTranslation } from 'react-i18next';
import { CustomTheme } from '../../theme/customTheme';

export interface LanguageButtonProps {
  label: string;
  language: string;
  tooltip: string;
}

export function LanguageButton(props: LanguageButtonProps) {
  const { label, language, tooltip } = props;

  const { i18n } = useTranslation();
  const size = React.useContext(ResponsiveContext);

  const active = i18n.language.startsWith(language);

  return (
    <Button
      plain={true}
      style={{ height: '18px' }}
      onClick={() => i18n.changeLanguage(language)}
      tip={size === 'small' ? undefined : tooltip}
    >
      <Box
        background={{ color: active ? 'white' : undefined }}
        border={{ color: 'white' }}
        round="xsmall"
        pad={{ horizontal: 'xsmall' }}
      >
        <Text
          size="12px"
          color={active ? CustomTheme.menu.background : 'white'}
          //color="white"
        >
          {label}
        </Text>
      </Box>
    </Button>
  );
}
