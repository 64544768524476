import * as React from 'react';
import { CustomTheme } from '../../theme/customTheme';
import { Section } from '../../components/Section';
import { ausstellungenListe_BE, ausstellungenListe_DE } from './daten';
import { AusstellungItem } from './AusstellungItem';
import { Box, Grid } from 'grommet';
import { useTranslation } from 'react-i18next';

export interface IAustellungItem {
  datum: string;
  ort: string;
  beschreibung: string;
}

export function Ausstellungen() {
  const { i18n } = useTranslation();

  const liste =
    i18n.language === 'be' ? ausstellungenListe_BE : ausstellungenListe_DE;

  return (
    <Section
      id="Ausstellungen"
      background={CustomTheme.ausstellungen.background}
      color={CustomTheme.ausstellungen.color}
      title="Ausstellungen"
    >
      <Box gap={'small'} border={{ side: 'between', color: '#ffffff33' }}>
        {liste.map((item, index) => (
          <Grid
            columns={['xsmall', 'auto']}
            rows={['auto', 'auto']}
            gap={{ column: 'small' }}
            key={index}
            areas={[
              { name: 'date', start: [0, 0], end: [0, 0] },
              { name: 'place', start: [0, 1], end: [0, 1] },
              { name: 'title', start: [1, 0], end: [1, 1] },
            ]}
          >
            <AusstellungItem data={item} />
          </Grid>
        ))}
      </Box>
    </Section>
  );
}
