import * as React from 'react';
import { Box, ResponsiveContext } from 'grommet';
import { LanguageButton } from './LanguageButton';

export function ChangeLanguage() {
  const size = React.useContext(ResponsiveContext);
  return (
    <Box direction="row" gap={size === 'small' ? 'medium' : 'small'}>
      <LanguageButton label="DE" language="de" tooltip="Deutsch" />
      <LanguageButton label="BE" language="be" tooltip="Беларускі" />
    </Box>
  );
}
