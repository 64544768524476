import '@mhb-ux/mhb-components/dist/themes/mhb/font.css';
import * as React from 'react';
import appConfig from './appConfig.json';
import {
  AppContextProvider,
  GlobalLoadingIndicator,
} from '@mhb-ux/mhb-components';
import { envConfig } from './envConfig';
import { HelmetConfiguration } from './components/HelmetConfiguration';
import { GrommetTheme } from './theme/grommetTheme';
import { IndexPage } from './IndexPage';

console.log(`App name: ${envConfig.APP_NAME}`);
console.log(`Environment: ${envConfig.ENVIRONMENT}`);
console.log(`Backend URL: ${envConfig.BACKEND_URL}`);
console.log(`Backend Mock: ${envConfig.BACKEND_MOCK === 'true'}`);
console.log(`Frontend port: ${envConfig.FRONTEND_PORT}`);
console.log(`Version: ${envConfig.PROJECT_VERSION}`);
console.log(`Build time: ${envConfig.BUILD_TIME}`);

const devTools = process.env.NODE_ENV === 'development';

export default function App(): JSX.Element {
  return (
    <AppContextProvider
      config={appConfig}
      grommetConfig={{
        full: false,
        theme: GrommetTheme,
      }}
      reactQueryConfig={{
        defaultOptions: {
          queries: {
            retry: false,
            staleTime: 60 * 1000, // 60 Sekunden
          },
        },
      }}
      devTools={devTools}
    >
      <HelmetConfiguration />
      <GlobalLoadingIndicator />

      <IndexPage />
    </AppContextProvider>
  );
}
