import * as React from 'react';
import { ResponsiveContext } from 'grommet';

export function useResponsiveContext() {
  const size = React.useContext(ResponsiveContext);

  return {
    isSmall: size === 'small',
    isMedium: size === 'medium',
    isLarge: size === 'large',
  };
}
