import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
//import { envConfig } from './envConfig';
import './i18n/i18n';
import './fonts/font.css';

/**
 * This function is required to defer rendering in the case
 * of using Mock Service Worker in development mode, see:
 * https://mswjs.io/docs/recipes/deferred-mounting
 */
const prepare = () => {
  // Disable the import here to stop using Mock Service Worker
  /*if (envConfig.BACKEND_MOCK === 'true') {
    const { mswStart } = require('./api/msw');
    return mswStart();
  }*/
  return Promise.resolve();
};

prepare().then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
