import * as React from 'react';
import { Box } from 'grommet';
import { CustomTheme } from '../../theme/customTheme';
import { ChangeLanguage } from './ChangeLanguage';
import { MenuButton } from './MenuButton';
import { useTranslation } from 'react-i18next';
import { useSticky } from './useSticky';
import { useResponsiveContext } from '../useResponsiveContext';
import { MenuAsDropdown } from './MenuAsDropdown';
import { menuItems } from './daten';

export function Menu() {
  const { t } = useTranslation();
  const { isSmall } = useResponsiveContext();
  const sticky = useSticky(isSmall ? 150 : 300);

  return (
    <Box
      pad={{ horizontal: 'large' }}
      direction="row"
      background={CustomTheme.menu.background}
      gap="large"
      height="35px"
      align="center"
      style={
        sticky
          ? {
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              zIndex: 10,
              boxShadow: '0 2px 24px 0 rgb(0 0 0 / 85%)',
              animation:
                '500ms ease-in-out 0s normal none 1 running fadeInDown',
            }
          : {}
      }
    >
      <Box width="300px" align="left">
        <ChangeLanguage />
      </Box>

      {!isSmall && (
        <Box direction="row" flex="grow" justify="between">
          {menuItems.map((item) => (
            <MenuButton
              title={t(item.title)}
              sectionId={item.sectionId}
              key={item.title}
            />
          ))}
        </Box>
      )}

      {isSmall && (
        <Box direction="row" flex="grow" justify="end">
          <MenuAsDropdown />
        </Box>
      )}
    </Box>
  );
}
