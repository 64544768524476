import * as React from 'react';
import { Box, ThemeContext } from 'grommet';
import { Button } from '@mhb-ux/mhb-components';
import { CustomTheme } from '../../theme/customTheme';
import * as Scroll from 'react-scroll';

export interface MenuButtonProps {
  title: string;
  sectionId: string;
  onClick?: () => void;
}

export function MenuButton(props: MenuButtonProps) {
  const { title, sectionId, onClick = () => {} } = props;

  const buttonThemeExt = {
    global: {
      hover: {
        background: CustomTheme.menu.hover,
        color: CustomTheme.menu.color,
      },
    },
  };

  const onButtonClick = () => {
    const element = document.getElementById(sectionId);
    if (element) {
      //element.scrollIntoView({ behavior: 'smooth' });
      Scroll.animateScroll.scrollTo(
        element.getBoundingClientRect().top - 100 + window.scrollY,
        {
          duration: 2000,
        }
      );
    }
    onClick();
  };

  return (
    <ThemeContext.Extend value={buttonThemeExt}>
      <Button plain hoverIndicator onClick={onButtonClick}>
        <Box height="35px" justify="center" pad="small">
          {title}
        </Box>
      </Button>
    </ThemeContext.Extend>
  );
}
