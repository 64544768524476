import * as React from 'react';
import { Box, DropButton } from 'grommet';
import { CustomTheme } from '../../theme/customTheme';
import { MenuButton } from './MenuButton';
import { FiMenu } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { menuItems } from './daten';

export function MenuAsDropdown() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <DropButton
      plain
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      dropAlign={{ right: 'right', top: 'bottom' }}
      dropContent={
        <Box background={CustomTheme.menu.background}>
          {menuItems.map((item) => (
            <MenuButton
              title={t(item.title)}
              sectionId={item.sectionId}
              key={item.title}
              onClick={() => setOpen(false)}
            />
          ))}
        </Box>
      }
    >
      <Box>
        <FiMenu size="24" />
      </Box>
    </DropButton>
  );
}
