import { IGalerieItem } from './Galerie';

export const galerieListe_BE: IGalerieItem[] = [
  {
    image: 'bilder/1.jpg',
    preview: 'preview/1.jpg',
    title: 'Мальта',
    size: '20x30',
    year: 2013,
    height: 446,
    width: 640,
  },
  {
    image: 'bilder/10112014-1.jpg',
    preview: 'preview/10112014-1.jpg',
    title: 'Пляж у Алкудзii, Маерка',
    size: '10x15',
    year: 2014,
    height: 701,
    width: 1000,
  },
  {
    image: 'bilder/10112014-2.jpg',
    preview: 'preview/10112014-2.jpg',
    title: 'Заход сонца',
    size: '60x100',
    year: 2014,
    height: 1663,
    width: 1000,
  },
  {
    image: 'bilder/10112014-3.jpg',
    preview: 'preview/10112014-3.jpg',
    title: 'Сад у Манкор дэ ла Валь.  Маерка',
    size: '10x15',
    year: 2014,
    height: 1450,
    width: 1000,
  },
  {
    image: 'bilder/12.jpg',
    preview: 'preview/12.jpg',
    title: 'Вока',
    size: '30x40',
    year: 2008,
    height: 1360,
    width: 1000,
  },
  {
    image: 'bilder/13.jpg',
    preview: 'preview/13.jpg',
    title: 'Дахi ў Берне',
    size: '25x25',
    year: 2013,
    height: 1016,
    width: 1000,
  },
  {
    image: 'bilder/14.jpg',
    preview: 'preview/14.jpg',
    title: 'Спадарыня ў капялюшы',
    size: '30x40',
    year: 2011,
    height: 1347,
    width: 1000,
  },
  {
    image: 'bilder/15.jpg',
    preview: 'preview/15.jpg',
    title: 'Дворык у Андалузii',
    size: '30x40',
    year: 2013,
    height: 1363,
    width: 1000,
  },
  {
    image: 'bilder/16.jpg',
    preview: 'preview/16.jpg',
    title: 'Чырвонае мора',
    size: '40x50',
    year: 2010,
    height: 1257,
    width: 1000,
  },
  {
    image: 'bilder/17.jpg',
    preview: 'preview/17.jpg',
    title: 'Нацюрморт з кветкамi на Сьвятое Уваскрэсенне',
    size: '30x40',
    year: 2010,
    height: 1340,
    width: 1000,
  },
  {
    image: 'bilder/20.jpg',
    preview: 'preview/20.jpg',
    title: 'Кабiнет-сад',
    size: '40x50',
    year: 2011,
    height: 753,
    width: 1000,
  },
  {
    image: 'bilder/22.jpg',
    preview: 'preview/22.jpg',
    title: 'У Ангельскiм cадзе',
    size: '40x50',
    year: 2011,
    height: 1333,
    width: 1000,
  },
  {
    image: 'bilder/23.jpg',
    preview: 'preview/23.jpg',
    title: 'Пасля дажджу',
    size: '50x70',
    year: 2013,
    height: 464,
    width: 632,
  },
  {
    image: 'bilder/25.jpg',
    preview: 'preview/25.jpg',
    title: 'Цётчын дом',
    size: '20x30',
    year: 2012,
    height: 701,
    width: 1000,
  },
  {
    image: 'bilder/26.jpg',
    preview: 'preview/26.jpg',
    title: 'Даша i Маша',
    size: '40x50',
    year: 2012,
    height: 1361,
    width: 1000,
  },
  {
    image: 'bilder/28.jpg',
    preview: 'preview/28.jpg',
    title: 'Заход сонца',
    size: '80x80',
    year: 2013,
    height: 991,
    width: 1000,
  },
  {
    image: 'bilder/29.jpg',
    preview: 'preview/29.jpg',
    title: 'Сэвiл´я, Андалузiя',
    size: '30x40',
    year: 2013,
    height: 1353,
    width: 1000,
  },
  {
    image: 'bilder/3.jpg',
    preview: 'preview/3.jpg',
    title: 'Арабская вёска',
    size: '50x70',
    year: 2011,
    height: 787,
    width: 1111,
  },
  {
    image: 'bilder/30-04-2016-01.jpg',
    preview: 'preview/30-04-2016-01.jpg',
    title: 'Абстракцыя',
    size: '15х20',
    year: 2016,
    height: 1280,
    width: 896,
  },
  {
    image: 'bilder/30-04-2016-02.jpg',
    preview: 'preview/30-04-2016-02.jpg',
    title: 'Абстракцыя',
    size: '20х30',
    year: 2016,
    height: 1280,
    width: 932,
  },
  {
    image: 'bilder/30-04-2016-03.jpg',
    preview: 'preview/30-04-2016-03.jpg',
    title: 'Абстракцыя',
    size: '30х40',
    year: 2016,
    height: 924,
    width: 1280,
  },
  {
    image: 'bilder/30-04-2016-04.jpg',
    preview: 'preview/30-04-2016-04.jpg',
    title: 'Абстракцыя',
    size: '20х30',
    year: 2017,
    height: 960,
    width: 1175,
  },
  {
    image: 'bilder/30-04-2016-05.jpg',
    preview: 'preview/30-04-2016-05.jpg',
    title: 'Рух',
    size: '20х30',
    year: 2017,
    height: 910,
    width: 1280,
  },
  {
    image: 'bilder/30-04-2016-08.jpg',
    preview: 'preview/30-04-2016-08.jpg',
    title: 'Абстракцыя',
    size: '20х30',
    year: 2017,
    height: 1280,
    width: 874,
  },
  {
    image: 'bilder/30-04-2016-09.jpg',
    preview: 'preview/30-04-2016-09.jpg',
    title: 'Сэрца',
    size: '20х30',
    year: 2010,
    height: 1181,
    width: 813,
  },
  {
    image: 'bilder/30-04-2016-10.jpg',
    preview: 'preview/30-04-2016-10.jpg',
    title: 'Абстракцыя',
    size: '20х30',
    year: 2017,
    height: 442,
    width: 640,
  },
  {
    image: 'bilder/30-04-2016-14.jpg',
    preview: 'preview/30-04-2016-14.jpg',
    title: 'Iспанскi iмпрэсiянiзм',
    size: '30х40',
    year: 2017,
    height: 640,
    width: 480,
  },
  {
    image: 'bilder/32.jpg',
    preview: 'preview/32.jpg',
    title: 'Ўспаміны пра Марка Шагала',
    size: '10x20',
    year: 2011,
    height: 1418,
    width: 1000,
  },
  {
    image: 'bilder/34.jpg',
    preview: 'preview/34.jpg',
    title: 'Бацькоускi дом',
    size: '30x40',
    year: 2010,
    height: 767,
    width: 1000,
  },
  {
    image: 'bilder/35.jpg',
    preview: 'preview/35.jpg',
    title: 'Нацюрморт з бутэлькамi',
    size: '50x70',
    year: 2012,
    height: 757,
    width: 1000,
  },
  {
    image: 'bilder/36.jpg',
    preview: 'preview/36.jpg',
    title: 'Яўгенаў дом',
    size: '20x30',
    year: 2012,
    height: 764,
    width: 1000,
  },
  {
    image: 'bilder/37.jpg',
    preview: 'preview/37.jpg',
    title: 'Спадарыня ў капялюшы',
    size: '30x40',
    year: 2011,
    height: 1345,
    width: 1000,
  },
  {
    image: 'bilder/4.jpg',
    preview: 'preview/4.jpg',
    title: 'Восень',
    size: '18x24',
    year: 2013,
    height: 1225,
    width: 1000,
  },
  {
    image: 'bilder/40.jpg',
    preview: 'preview/40.jpg',
    title: 'Нацюрморт з кветкамi',
    size: '50x50',
    year: 2014,
    height: 1201,
    width: 1000,
  },
  {
    image: 'bilder/41.jpg',
    preview: 'preview/41.jpg',
    title: 'Макi',
    size: '10x15',
    year: 2015,
    height: 690,
    width: 999,
  },
  {
    image: 'bilder/42.jpg',
    preview: 'preview/42.jpg',
    title: 'Заход сонца на Тэнэрыфе',
    size: '10x15',
    year: 2015,
    height: 696,
    width: 1000,
  },
  {
    image: 'bilder/51.jpg',
    preview: 'preview/51.jpg',
    title: 'Чырвонае мора',
    size: '30x40',
    year: 2009,
    height: 480,
    width: 640,
  },
  {
    image: 'bilder/52.jpg',
    preview: 'preview/52.jpg',
    title: 'Краявiд',
    size: '15x20',
    year: 2015,
    height: 707,
    width: 1000,
  },
  {
    image: 'bilder/55.jpg',
    preview: 'preview/55.jpg',
    title: 'Вясна',
    size: '15x20',
    year: 2016,
    height: 703,
    width: 1000,
  },
  {
    image: 'bilder/56.jpg',
    preview: 'preview/56.jpg',
    title: 'Энергiя',
    size: '70x70',
    year: 2016,
    height: 960,
    width: 964,
  },
  {
    image: 'bilder/58.jpg',
    preview: 'preview/58.jpg',
    title: 'Джунглi',
    size: '20x30',
    year: 2016,
    height: 750,
    width: 1000,
  },
  {
    image: 'bilder/59.jpg',
    preview: 'preview/59.jpg',
    title: 'Нябёсы',
    size: '20x30',
    year: 2016,
    height: 1280,
    width: 938,
  },
  {
    image: 'bilder/60.jpg',
    preview: 'preview/60.jpg',
    title: 'Вокны',
    size: '50x70',
    year: 2009,
    height: 1220,
    width: 960,
  },
  {
    image: 'bilder/61.jpg',
    preview: 'preview/61.jpg',
    title: 'Матылёк',
    size: '20x20',
    year: 2010,
    height: 717,
    width: 737,
  },
  {
    image: 'bilder/62.jpg',
    preview: 'preview/62.jpg',
    title: 'Егiпет, Марса Алам',
    size: '50x70',
    year: 2009,
    height: 761,
    width: 1000,
  },
  {
    image: 'bilder/7.jpg',
    preview: 'preview/7.jpg',
    title: 'Ланцароце',
    size: '50x70',
    year: 2012,
    height: 700,
    width: 1000,
  },
  {
    image: 'bilder/8.jpg',
    preview: 'preview/8.jpg',
    title: 'Берн',
    size: '40x50',
    year: 2013,
    height: 1337,
    width: 1000,
  },
  {
    image: 'bilder/9.jpg',
    preview: 'preview/9.jpg',
    title: 'Нацюрморт з гарлачыкам, яблыкам i ...',
    size: '40x50',
    year: 2010,
    height: 490,
    width: 640,
  },
  {
    image: 'bilder/sept2017-1.jpg',
    preview: 'preview/sept2017-1.jpg',
    title: 'Паудневы завулак',
    size: '50х70',
    year: 2012,
    height: 912,
    width: 693,
  },
  {
    image: 'bilder/sept2017-2.jpg',
    preview: 'preview/sept2017-2.jpg',
    title: 'Пляж',
    size: '80х80',
    year: 2012,
    height: 640,
    width: 634,
  },
  {
    image: 'bilder/sept2017-3.jpg',
    preview: 'preview/sept2017-3.jpg',
    title: 'Абстракцыя',
    size: '20х30',
    year: 2017,
    height: 640,
    width: 442,
  },
  {
    image: 'bilder/sept2017-4.jpg',
    preview: 'preview/sept2017-4.jpg',
    title: 'Летнi дамок',
    size: '20х30',
    year: 2017,
    height: 640,
    width: 466,
  },
  {
    image: 'bilder/sept2017-5.jpg',
    preview: 'preview/sept2017-5.jpg',
    title: 'Абстракцыя',
    size: '20х30',
    year: 2017,
    height: 612,
    width: 420,
  },
  {
    image: 'bilder/april2019/3.jpg',
    preview: 'preview/april2019/3.jpg',
    title: 'Жанчына',
    size: '40x50',
    year: 2017,
    height: 482,
    width: 640,
  },
  {
    image: 'bilder/april2019/4.jpg',
    preview: 'preview/april2019/4.jpg',
    title: 'Краявiд',
    size: '20х30',
    year: 2018,
    height: 640,
    width: 446,
  },
  {
    image: 'bilder/april2019/5.jpg',
    preview: 'preview/april2019/5.jpg',
    title: 'Абстракцыя',
    size: '20х30',
    year: 2018,
    height: 508,
    width: 640,
  },
  {
    image: 'bilder/april2019/6.jpg',
    preview: 'preview/april2019/6.jpg',
    title: 'Фантазiя',
    size: '50x80',
    year: 2018,
    height: 640,
    width: 454,
  },
  {
    image: 'bilder/februar2020/1.jpg',
    preview: 'preview/februar2020/1.jpg',
    title: 'Фрыда',
    size: '29,5 х 40',
    year: 2019,
    height: 640,
    width: 466,
  },
  {
    image: 'bilder/februar2020/10.jpg',
    preview: 'preview/februar2020/10.jpg',
    title: 'Без назвы',
    size: '40x49',
    year: 2019,
    height: 631,
    width: 791,
  },
  {
    image: 'bilder/februar2020/11.jpg',
    preview: 'preview/februar2020/11.jpg',
    title: 'Вяселыя шары',
    size: '49x49',
    year: 2019,
    height: 640,
    width: 637,
  },
  {
    image: 'bilder/februar2020/12.jpg',
    preview: 'preview/februar2020/12.jpg',
    title: 'Кветкi',
    size: '24х18',
    year: 2019,
    height: 472,
    width: 640,
  },
  {
    image: 'bilder/februar2020/13.jpg',
    preview: 'preview/februar2020/13.jpg',
    title: 'Цуд вясны',
    size: '49,5x49',
    year: 2019,
    height: 640,
    width: 636,
  },
  {
    image: 'bilder/februar2020/2.jpg',
    preview: 'preview/februar2020/2.jpg',
    title: 'Сара',
    size: '29х39',
    year: 2019,
    height: 640,
    width: 452,
  },
  {
    image: 'bilder/februar2020/3.jpg',
    preview: 'preview/februar2020/3.jpg',
    title: 'Фантазiя',
    size: '50х39',
    year: 2019,
    height: 640,
    width: 512,
  },
  {
    image: 'bilder/februar2020/4.jpg',
    preview: 'preview/februar2020/4.jpg',
    title: 'Жанчына',
    size: '69,5х49',
    year: 2019,
    height: 1005,
    width: 709,
  },
  {
    image: 'bilder/februar2020/5.jpg',
    preview: 'preview/februar2020/5.jpg',
    title: 'Японка',
    size: '20х30',
    year: 2019,
    height: 444,
    width: 640,
  },
  {
    image: 'bilder/februar2020/6.jpg',
    preview: 'preview/februar2020/6.jpg',
    title: 'Стыль мадэрны',
    size: '49х39',
    year: 2019,
    height: 480,
    width: 480,
  },
  {
    image: 'bilder/februar2020/7.jpg',
    preview: 'preview/februar2020/7.jpg',
    title: 'Вока',
    size: '40х50',
    year: 2019,
    height: 1193,
    width: 961,
  },
  {
    image: 'bilder/februar2020/9.jpg',
    preview: 'preview/februar2020/9.jpg',
    title: 'Без назвы',
    size: '49x39',
    year: 2019,
    height: 961,
    width: 1211,
  },
  {
    image: 'bilder/juni2018/Abstrakt20x30-2017-1.jpg',
    preview: 'preview/juni2018/Abstrakt20x30-2017-1.jpg',
    title: 'Абстракцыя',
    size: '20х30',
    year: 2017,
    height: 640,
    width: 472,
  },
  {
    image: 'bilder/juni2018/Abstrakt20x30-2017-2.jpg',
    preview: 'preview/juni2018/Abstrakt20x30-2017-2.jpg',
    title: 'Абстракцыя',
    size: '20х30',
    year: 2017,
    height: 640,
    width: 524,
  },
  {
    image: 'bilder/juni2018/Abstrakt20x30-2017.jpg',
    preview: 'preview/juni2018/Abstrakt20x30-2017.jpg',
    title: 'Абстракцыя',
    size: '20х30',
    year: 2017,
    height: 592,
    width: 640,
  },
  {
    image: 'bilder/juni2018/Abstrakt20x30-2018.jpg',
    preview: 'preview/juni2018/Abstrakt20x30-2018.jpg',
    title: 'Абстракцыя',
    size: '20х30',
    year: 2018,
    height: 629,
    width: 395,
  },
  {
    image: 'bilder/juni2018/Abstrakt30x30-2017.jpg',
    preview: 'preview/juni2018/Abstrakt30x30-2017.jpg',
    title: 'Абстракцыя',
    size: '30х30',
    year: 2017,
    height: 624,
    width: 640,
  },
  {
    image: 'bilder/juni2018/Abstrakt30x40-2017-1.jpg',
    preview: 'preview/juni2018/Abstrakt30x40-2017-1.jpg',
    title: 'Абстракцыя',
    size: '30х40',
    year: 2017,
    height: 640,
    width: 430,
  },
  {
    image: 'bilder/juni2018/Abstrakt30x40-2017-2.jpg',
    preview: 'preview/juni2018/Abstrakt30x40-2017-2.jpg',
    title: 'Абстракцыя',
    size: '30х40',
    year: 2017,
    height: 640,
    width: 444,
  },
  {
    image: 'bilder/juni2018/Abstrakt30x40-2017.jpg',
    preview: 'preview/juni2018/Abstrakt30x40-2017.jpg',
    title: 'Абстракцыя',
    size: '30х40',
    year: 2017,
    height: 1278,
    width: 981,
  },
  {
    image: 'bilder/juni2018/Abstrakt30x40-2018.jpg',
    preview: 'preview/juni2018/Abstrakt30x40-2018.jpg',
    title: 'Абстракцыя',
    size: '30х40',
    year: 2018,
    height: 640,
    width: 470,
  },
  {
    image: 'bilder/juni2018/Abstrakt50x80-2017.jpg',
    preview: 'preview/juni2018/Abstrakt50x80-2017.jpg',
    title: 'Абстракцыя',
    size: '50х80',
    year: 2017,
    height: 640,
    width: 448,
  },
  {
    image: 'bilder/juni2018/BunteFantasie80x80-2018.jpg',
    preview: 'preview/juni2018/BunteFantasie80x80-2018.jpg',
    title: 'Каляровая фантазiя',
    size: '80x80',
    year: 2018,
    height: 640,
    width: 506,
  },
  {
    image: 'bilder/juni2018/Romantik50x80-2017.jpg',
    preview: 'preview/juni2018/Romantik50x80-2017.jpg',
    title: 'Рамантык',
    size: '50x80',
    year: 2017,
    height: 640,
    width: 482,
  },
  {
    image: 'bilder/juni2018/Selbstbildnis30x40-2017.jpg',
    preview: 'preview/juni2018/Selbstbildnis30x40-2017.jpg',
    title: 'Аутапартрэт',
    size: '30x40',
    year: 2017,
    height: 640,
    width: 445,
  },
  {
    image: 'bilder/juni2018/SpanischeImpressionen40x50-2011.jpg',
    preview: 'preview/juni2018/SpanischeImpressionen40x50-2011.jpg',
    title: 'Iспанскi iмпрэсiянiзм',
    size: '40x50',
    year: 2011,
    height: 884,
    width: 658,
  },
  {
    image: 'bilder/juni2018/Stadtleben80x80-2017.jpg',
    preview: 'preview/juni2018/Stadtleben80x80-2017.jpg',
    title: 'Гарадское жыцце',
    size: '80x80',
    year: 2017,
    height: 636,
    width: 640,
  },
  {
    image: 'bilder/juni2018/Unterwegs30x40-2017.jpg',
    preview: 'preview/juni2018/Unterwegs30x40-2017.jpg',
    title: 'У дарозе',
    size: '30x40',
    year: 2017,
    height: 640,
    width: 426,
  },
  {
    image: 'bilder/mai2021/1.jpg',
    preview: 'preview/mai2021/1.jpg',
    title: 'Бухта',
    size: '40x150',
    year: 2021,
    height: 315,
    width: 947,
  },
  {
    image: 'bilder/mai2021/10.jpg',
    preview: 'preview/mai2021/10.jpg',
    title: 'Матылек',
    size: '44 x 44',
    year: 2020,
    height: 584,
    width: 640,
  },
  {
    image: 'bilder/mai2021/11.jpg',
    preview: 'preview/mai2021/11.jpg',
    title: 'Фелiкс',
    size: '50 x 50',
    year: 2021,
    height: 480,
    width: 489,
  },
  {
    image: 'bilder/mai2021/2.jpg',
    preview: 'preview/mai2021/2.jpg',
    title: 'Андалузскi двор',
    size: '60 x 90',
    year: 2021,
    height: 1024,
    width: 652,
  },
  {
    image: 'bilder/mai2021/4.jpg',
    preview: 'preview/mai2021/4.jpg',
    title: 'Тэкстыльная карцiна',
    size: '60 x 60',
    year: 2020,
    height: 640,
    width: 632,
  },
  {
    image: 'bilder/mai2021/5.jpg',
    preview: 'preview/mai2021/5.jpg',
    title: 'Kветкi',
    size: '60 x 60',
    year: 2020,
    height: 512,
    width: 480,
  },
  {
    image: 'bilder/mai2021/6.jpg',
    preview: 'preview/mai2021/6.jpg',
    title: 'Павук',
    size: '60 x 60',
    year: 2020,
    height: 640,
    width: 586,
  },
  {
    image: 'bilder/mai2021/7.jpg',
    preview: 'preview/mai2021/7.jpg',
    title: 'Без назвы',
    size: '60 x 90',
    year: 2020,
    height: 640,
    width: 498,
  },
  {
    image: 'bilder/mai2021/8.jpg',
    preview: 'preview/mai2021/8.jpg',
    title: 'COVID-2019',
    size: '60 x 70',
    year: 2019,
    height: 640,
    width: 522,
  },
  {
    image: 'bilder/mai2021/9_1.jpg',
    preview: 'preview/mai2021/9_1.jpg',
    title: 'Краявiд',
    size: '60 x 70',
    year: 2020,
    height: 480,
    width: 609,
  },
  {
    image: 'bilder/februar2022/IMG_4201.jpg',
    preview: 'preview/februar2022/IMG_4201.jpg',
    title: 'Спадарыня',
    year: 2021,
    size: '40 x 70',
    height: 1173,
    width: 768,
  },
  {
    image: 'bilder/februar2022/IMG_6173.jpg',
    preview: 'preview/februar2022/IMG_6173.jpg',
    title: 'Летнiя кветкi',
    year: 2022,
    size: '40 x 60',
    height: 430,
    width: 640,
  },
  {
    image: 'bilder/februar2022/IMG_6321.jpg',
    preview: 'preview/februar2022/IMG_6321.jpg',
    title: 'Тэкстыльная карцiна',
    year: 2022,
    size: '60 x 90',
    height: 899,
    width: 646,
  },
  {
    image: 'bilder/februar2022/IMG_7028.jpg',
    preview: 'preview/februar2022/IMG_7028.jpg',
    title: 'Абстракцыя',
    year: 2022,
    size: '20 x 20',
    height: 640,
    width: 592,
  },
  {
    image: 'bilder/februar2022/IMG_7031.jpg',
    preview: 'preview/februar2022/IMG_7031.jpg',
    title: 'Абстракцыя',
    year: 2022,
    size: '20 x 30',
    height: 389,
    width: 640,
  },
  {
    image: 'bilder/februar2022/IMG_7105.jpg',
    preview: 'preview/februar2022/IMG_7105.jpg',
    title: 'Абстракцыя',
    year: 2022,
    size: '20 x 30',
    height: 336,
    width: 640,
  },
  {
    image: 'bilder/februar2022/IMG_7721.jpg',
    preview: 'preview/februar2022/IMG_7721.jpg',
    title: 'Абстракцыя',
    year: 2022,
    size: '40 х 70',
    height: 640,
    width: 449,
  },
  {
    image: 'bilder/februar2022/IMG_7724.jpg',
    preview: 'preview/februar2022/IMG_7724.jpg',
    title: 'Iспанскi  iмпрэсiянiзм',
    year: 2022,
    size: '60 x 80',
    height: 640,
    width: 480,
  },
  {
    image: 'bilder/februar2022/IMG_7726.jpg',
    preview: 'preview/februar2022/IMG_7726.jpg',
    title: 'Летнiя кветкi',
    year: 2022,
    size: '50 x 80',
    height: 440,
    width: 640,
  },
  {
    image: 'bilder/april2024/1.jpg',
    preview: 'preview/april2024/1.jpg',
    title: 'Абстракцыя',
    year: 2024,
    size: '120 x 70',
    height: 640,
    width: 483,
  },
  {
    image: 'bilder/april2024/2.jpg',
    preview: 'preview/april2024/2.jpg',
    title: 'Абстракцыя',
    year: 2024,
    size: '80 x 80',
    height: 629,
    width: 640,
  },
  {
    image: 'bilder/april2024/3.jpg',
    preview: 'preview/april2024/3.jpg',
    title: 'Абстракцыя',
    year: 2023,
    size: '80 х 60',
    height: 640,
    width: 496,
  },
  {
    image: 'bilder/april2024/4.JPG',
    preview: 'preview/april2024/4.JPG',
    title: 'Вясна',
    year: 2024,
    size: '80 x 80',
    height: 1600,
    width: 1522,
  },
  {
    image: 'bilder/april2024/5.jpg',
    preview: 'preview/april2024/5.jpg',
    title: 'Тэкстыльная карцiна',
    year: 2023,
    size: '120 x 120',
    height: 640,
    width: 638,
  },
];
