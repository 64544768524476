import { IAustellungItem } from './Ausstellungen';

export const ausstellungenListe_DE: IAustellungItem[] = [
  {
    datum: '2024',
    ort: 'München',
    beschreibung: 'Galerie an Gaiglstrasse 10',
  },
  {
    datum: '2022',
    ort: 'München',
    beschreibung: 'Sonderausstellung "Kunst im Garten", Schleissheimerstr. 221',
  },
  {
    datum: '2020-2021',
    ort: 'München',
    beschreibung:
      '"Die Schönheit der Atmosphäre".  Im Kasugallery in der Theresienstrasse 19',
  },
  {
    datum: '2018',
    ort: 'München',
    beschreibung:
      '"Zauberwelten im Klang der Bilder". Im Deutschen Herzzentrum in der Lazarettstrasse 36',
  },
  {
    datum: '2017',
    ort: 'Ingolstadt',
    beschreibung: '1. Ingoldstädter ART EXPO INTERNATIONAL',
  },
  {
    datum: '2016',
    ort: 'München',
    beschreibung:
      '"Empfindungen der Seele". Kunst-, Kultur- und Sprachenzentrum »Mundart-e« in der Kaulbachstr. 71',
  },
  {
    datum: '2015',
    ort: 'München',
    beschreibung:
      '"Кünstler für die Ukraine". Im Kulturpavillon am Romanplatz.',
  },
  {
    datum: '2012',
    ort: 'München',
    beschreibung: '"Werde, der du bist". Im Kulturpavillon am Romanplatz.',
  },
  {
    datum: '2009',
    ort: 'München',
    beschreibung: 'Galerie an Schleißheimerstr. 221',
  },
];

export const ausstellungenListe_BE: IAustellungItem[] = [
  {
    datum: '2024',
    ort: 'Мюнхен',
    beschreibung: 'Галерэя на вулiцы Gaiglstrasse 10',
  },
  {
    datum: '2022',
    ort: 'Мюнхен',
    beschreibung:
      'Адмысловая выстава "Мастацтва ў садзе", Schleissheimerstr. 221',
  },
  {
    datum: '2020-2021',
    ort: 'Мюнхен',
    beschreibung:
      '"Прыгажосць атмасферы".  У Kasugallery на вулiцы Theresienstrasse 19',
  },
  {
    datum: '2018',
    ort: 'Мюнхен',
    beschreibung:
      '"Чароўныя мiры ў гучаннi карцiн". У кардыялагiчным цэнтры на вулiцы Lazarettstrasse 36',
  },
  {
    datum: '2017',
    ort: 'Iнгольштадт',
    beschreibung: '1 Мiжнароднае Арт экспа Iнгольштадт',
  },
  {
    datum: '2016',
    ort: 'Мюнхен',
    beschreibung:
      '"Адчуваннi  душы". У Цэнтры культуры , мастацтва i мовазнаўства на вулiцы Kaulbachstr. 71',
  },
  {
    datum: '2015',
    ort: 'Мюнхен',
    beschreibung: '"Мастакi для Украiны". У павiльене культуры на Romanplatz.',
  },
  {
    datum: '2012',
    ort: 'Мюнхен',
    beschreibung:
      '"Стань тым, кiм ты есьць". У павiльене культуры на Romanplatz.',
  },
  {
    datum: '2009',
    ort: 'Мюнхен',
    beschreibung: 'Галерэя на вуліцы Schleißheimerstr. 221',
  },
];
