import { PageNotFound } from '@mhb-ux/mhb-components';
import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { MainPage } from '../pages/MainPage';

export default function Routing() {
  return (
    <Routes>
      <Route path="/*" element={<MainPage />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
