export function emailRender(email: string) {
  let i = 0;
  return (
    <span>
      {email.split('').map((el) => {
        return <span key={i++}>{el}</span>;
      })}
    </span>
  );
}
