import * as React from 'react';
import { Tooltip, TooltipProps } from '@mhb-ux/mhb-components';
import { useResponsiveContext } from './useResponsiveContext';

interface ResposiveTooltipProps extends TooltipProps {}

export function ResponsiveTooltip(props: ResposiveTooltipProps) {
  const { isSmall } = useResponsiveContext();
  if (isSmall) return <>{props.children}</>;
  return <Tooltip {...props} />;
}
