import * as React from 'react';
import { CustomTheme } from '../../theme/customTheme';
import { Section } from '../../components/Section';
import { useTranslation } from 'react-i18next';
import { presseListe_BE, presseListe_DE } from './daten';
import { Grid } from 'grommet';
import { PresseItem } from './PresseItem';
import { useResponsiveContext } from '../../components/useResponsiveContext';

export interface IPresseItem {
  document: string;
  preview: string;
  title: string;
}

export function Presse() {
  const { i18n } = useTranslation();
  const { isSmall } = useResponsiveContext();

  const liste = i18n.language === 'be' ? presseListe_BE : presseListe_DE;

  return (
    <Section
      id="Presse"
      background={CustomTheme.presse.background}
      color={CustomTheme.presse.color}
      title="Presse"
    >
      <Grid
        columns={{
          count: isSmall ? 2 : 4,
          size: 'auto',
        }}
        gap="large"
      >
        {liste.map((item, index) => (
          <PresseItem data={item} key={index} />
        ))}
      </Grid>
    </Section>
  );
}
